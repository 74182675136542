<template>
  <v-select
    class="mr-1 ml-1"
    menu-props="auto"
    :items="countries"
    v-model="selectedCountryCode"
    item-text="name"
    item-value="code2"
    label="Country"
    outlined
    dense
    v-on:change="$emit('change', $event)"
  >
  </v-select>
</template>

<script>
import Carriers from "@/services/Carriers.js";

export default {
  name: "CountrySelect",
    props: ["countryCode"],
  model: {
    event: "change",
  },
  data: function () {
    return {
      countries: [],
      selectedCountryCode: null,
    };
  },
  methods: {
    async getCountriesData() {
      Carriers.getCountries().then((countries) => {
        this.countries = countries;
      });
    },
  },
  created: function () {
    this.getCountriesData();
    this.selectedCountryCode = this.countryCode
  },
};
</script>

<style>
</style>