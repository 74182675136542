<template>
  <div>
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">{{ title }}</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <v-card :loading="loading" class="mb-4 pa-6">
        <v-row>
          <v-col cols="6">
            <v-select
              menu-props="auto"
              v-model="selectedServiceGroup"
              :items="serviceGroups"
              item-text="description"
              return-object
              label="Select Delivery Service (Service Group)"
              outlined
              dense
            ></v-select>
          </v-col>
          
                <v-col cols="6">
                    <CountriesSelect @change="selectCountry" />
                </v-col>
                
                <v-col cols="4">
                  <v-text-field
                    v-model="postCode"
                    label="Post Code"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
             <v-col cols="4" >
                  <v-text-field
                    type="number"
                    v-model="numberOfPackages"
                    label="Number of Packages"
                    dense
                    outlined
                    step="1"
                  ></v-text-field>
                </v-col>
                 <v-col cols="4" >
                  <v-text-field
                    type="number"
                    v-model="maxWeight"
                    label="Weight of heaviest Package (Kg)"
                    dense
                    outlined
                    step="1"
                  ></v-text-field>
                </v-col>
                 <company-select
              @chosen="setCompany"
              v-model="companySelected"
          ></company-select>
                  <v-col cols="12" align="center">
                  <v-btn
                    :disabled="
                      !maxWeight ||
                      !numberOfPackages ||
                      !postCode ||
                      !countryCode
                    "
                    color="primary"
                    outlined
                    @click="getAvailableCarrierServices"
                    >Get Available Services
                  </v-btn>
                </v-col>
       
        </v-row>
        <v-row>
          <v-col cols="12" >
            <v-card outlined class="pa-2" v-if="availableCarrierServices.length">
              <v-card-title
                >Available Carrier Services For 
                {{ loadedServiceGroup.description }}</v-card-title
              >
              <v-data-table
                :headers="availableCarrierServicesHeaders"
                :items="availableCarrierServices"
                :items-per-page="5"
                v-if="availableCarrierServices.length"
              >
                <template v-slot:item.percentage_charge_per_order="{ item }">
                  {{ convertToPercentage(item.percentage_charge_per_order) }}
                </template>
                <template v-slot:item.company_id="{ item }">
                  {{item.company_id ? 'YES' : 'NO'}}
                </template>
                
            
              </v-data-table>
            </v-card>
          </v-col>
      
        </v-row>
      </v-card>
    </div>
    <v-snackbar v-model="snackbar" :timeout="3000">
      {{ text }}
      <v-btn color="blue" text @click="snackbar = false"></v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import Carriers from "@/services/Carriers.js";
import FormatData from '@/mixins/FormatData';
import CountriesSelect from "../../components/partials/CountriesSelect";
import CompanySelect from "@/components/forms/CompanySelect";
export default {
  mixins: [FormatData],
   components: {
    CountriesSelect, CompanySelect
   },
  data() {
    return {
      validForm: true,
      // Title
      title: "Available Carrier Services",
      // Snackbar
      snackbar: false,
      text: "",
      loading: false,

      serviceGroups: [],
      selectedServiceGroup: null,
      loadedServiceGroup: null,

      countryCode: 'GB',
      postCode: '',
      maxWeight: null, 
      numberOfPackages: null,

      

      availableCarrierServices: [],
      availableCarrierServicesHeaders: [
        { text: "Company Specific Rates", value: "company_id" },
        { text: "Carrier Name", value: "carrier_name" },
        { text: "Service", value: "service" },
        { text: "Rate Description", value: "rate_description" },
        { text: "From Zone", value: "from_zone_reference" },
        { text: "To Zone", value: "to_zone_reference" },
        { text: "Country Code", value: "country_code" },
        { text: "Post Code Area", value: "post_code_area" },
        { text: "Post Code District", value: "post_code_district" },
        { text: "Post Code Start", value: "post_code_start" },
        { text: "Number Of Packages", value: "num_of_packages" },
        { text: "Base Cost", value: "base_cost" },
        { text: "Flat Charge Per Order", value: "flat_charge_per_order" },
        { text: "Percentage Charge Per Order", value: "percentage_charge_per_order" },
        { text: "Flat Charge Per Package", value: "flat_charge_per_package" },
        { text: "Percentage Charge Per Package", value: "percentage_charge_per_package" },
        { text: "Total Cost", value: "total_cost" },
      ],
      companySelected: null,

    
    };
  },

  created() {
    this.getServiceGroups();
    
  },
  methods: {
    setCompany(company) {
      this.companySelected = company;
    
    },
    getServiceGroups() {
      this.loading = true
      Carriers.getServiceGroups(true)
        .then((response) => {
          this.loading = false;
          this.snackbar = true;
          this.text = `${response.message}`;
          this.serviceGroups = response.data;
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
        });
    },
    getAvailableCarrierServices() {
      this.loading = true;
      this.availableCarrierServices = []
      let companyId = this.companySelected ? this.companySelected.id : null
      Carriers.getAvailableCarrierServices(this.selectedServiceGroup.service, this.countryCode, this.postCode, this.numberOfPackages, this.maxWeight, companyId)
        .then((response) => {
          this.loading = false;
          this.availableCarrierServices = response.data;
          this.loadedServiceGroup = this.selectedServiceGroup
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.loading = false;
        });
    },
    selectCountry(countryCode){
        this.countryCode = countryCode
    }
  },
};
</script>
<style></style>
